body {
  background: rgba(0, 0, 0, 1);
  font-family: Verdana, Helvetica, Arial, sans-serif;
  margin: 0;
}
main {
  background: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.9);
}
.webgl {
  left: 0;
  outline: none;
  position: fixed;
  top: 0;
}
.flex-container {
  align-items: center;
  display: flex;
  font-size: 18px;
  height: 100vh;
  margin-inline: auto;
  max-width: 800px;
  padding-inline: 2rem;
  position: relative;
}
.flex-container h2 {
  font-size: 1em;
}
.flex-container a {
  color: #cac4ed;
  font-family: Georgia, serif;
  font-size: 1.75em;
  text-decoration: none;
}
.flex-container a:hover {
  color: rgb(0, 204, 0);
}
.first-letter::first-letter {
  font-family: Georgia, serif;
  font-size: 1.5em;
  font-weight: 600;
}
/* Semantic Elements */
.visibility-hidden {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}